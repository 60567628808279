import logo from './images/logo_gmt.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="App-text">
        We're Cooking Up Something Delicious, Stay Hungry!
        </p>
        <a
          className="App-link"
          href="mailto:ncubelee04@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Say Hi To The Chef!
        </a>
      </header>
    </div>
  );
}

export default App;
